<template>
  <div>
    <div class="card info-detail">
      <div class="d-flex justify-content-between align-items-center">
        <div class="company-wrapper d-flex align-items-center">
          <img
            :src="dataCompany.logo"
            v-if="dataCompany.logo"
            class="mr-3"
            style="width: 90px; height: auto"
          />
          <div class="">
            <div class="title-company">{{ dataCompany.nama }}</div>
            <div class="email-company">{{ dataCompany.email }}</div>
          </div>
        </div>

        <div class="address-company">
          {{ dataCompany.alamat }}
        </div>
      </div>

      <div
        class="d-flex align-items-center justify-content-between mt-5 header-detail"
      >
        <div class="">
          <div class="title-payroll mb-3">SLIP GAJI</div>
          <div class="d-flex align-items-center item-detail">
            <div class="text-header">Periode bulan</div>
            <div class="text-bold">
              : {{ formatDate(`${formData.bulan}-01`, "MMM YYYY") }}
            </div>
          </div>
          <div class="d-flex align-items-center item-detail">
            <div class="text-header">tanggal bayar</div>
            <div class="text-bold">
              : {{ formatDate(`${formData.tanggal_bayar}`, "DD MMM YYYY") }}
            </div>
          </div>
        </div>
        <div class="">
          <div class="d-flex align-items-center item-detail">
            <div class="text-header">NAMA</div>
            <div class="text-bold">: {{ formData.nama_karyawan }}</div>
          </div>
          <div class="d-flex align-items-center item-detail">
            <div class="text-header">NIK</div>
            <div class="text-bold">: {{ formData.id_karyawan }}</div>
          </div>
          <div class="d-flex align-items-center item-detail">
            <div class="text-header">DEPARTEMEN</div>
            <div class="text-bold">
              : {{ formData.nama_divisi }}
            </div>
          </div>

        </div>
        <div class="">
          <div class="d-flex align-items-center item-detail">
            <div class="text-header">Jabatan</div>
            <div class="text-bold">: {{ formData.nama_jabatan }}</div>
          </div>
          <div class="d-flex align-items-center item-detail">
            <div class="text-header">Aktif Sejak</div>
            <div class="text-bold">
              : {{ formatDate(formData.tgl_masuk, "DD MMM YYYY") }}
            </div>
          </div>
          <div class="d-flex align-items-center item-detail">
            <div class="text-header">REKENING</div>
            <div class="text-bold">
              : {{ formData.nomor_rekening }} ({{ formData.nama_bank }})
            </div>
          </div>
        </div>
      </div>

      <table class="table table-payroll-slip mt-5 table-striped">
        <tbody>
          <tr>
            <td colspan="2" class="td-block header-table-blue">Pemasukan</td>
          </tr>
          <tr v-for="(value, index) in ListItem.pemasukan" :key="index">
            <td class="border-colon-right">{{ value.nama }}</td>
            <td class="border-colon-right text-right" style="width: 20%">
              {{ formatMoney(value.nilai) }}
            </td>
          </tr>
          <tr>
            <td class="td-block text-center header-table-blue">
              TOTAL PEMASUKAN
            </td>
            <td class="text-right text-bold">
              {{
                formatMoney(ListItem.nilai_pemasukan)
              }}
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td colspan="2" class="td-block header-table-blue">Subsidi</td>
          </tr>
          <tr v-for="(value, index) in ListItem.subsidi" :key="index">
            <td class="border-colon-right">{{ value.nama }}</td>
            <td class="border-colon-right text-right" style="width: 20%">
              {{ formatMoney(value.nilai) }}
            </td>
          </tr>
          <tr>
            <td class="td-block text-center header-table-blue">
              TOTAL subsidi
            </td>
            <td class="text-right text-bold">
              {{
                formatMoney(ListItem.nilai_subsidi)
              }}
            </td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td colspan="2" class="td-block header-table-red">Potongan</td>
          </tr>
          <tr v-for="(value, index) in ListItem.potongan" :key="index">
            <td class="border-colon-right">{{ value.nama }}</td>
            <td class="border-colon-right text-right" style="width: 20%">
              {{ formatMoney(value.nilai) }}
            </td>
          </tr>
          <tr>
            <td class="td-block text-center header-table-red">
              TOTAL Potongan
            </td>
            <td class="text-right text-bold">
              {{ formatMoney(ListItem.nilai_potongan) }}
            </td>
          </tr>
          <tr>
            <td class="td-block text-center header-table-blue">
              Total Gaji yang diterima
            </td>
            <td class="text-right text-bold">
              {{
                formatMoney(
                  ListItem.nilai_pemasukan +
                    ListItem.nilai_subsidi -
                    (ListItem.nilai_potongan + ListItem.nilai_subsidi)
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/id";
export default {
  name: "ComponentSlipFinanta",

  props: {
    payrollItem: Object,
    payrollData: Object,
    company: Object,
  },

  data() {
    return {
      dataCompany: {
        logo: "",
        email: "",
        nama: "",
        alamat: "",
      },
      ListItem: {
        pemasukan: [],
        potongan: [],
        subsidi: [],
        nilai_pemasukan: 0,
        nilai_potongan: 0,
        nilai_subsidi: 0,
      },
      DataTable: "",
      selectAll: false,
      formData: {
        id: "",
        id_payroll: "",
        kategori: "Gaji",
        bulan: "",
        tanggal_bayar: "",
        keterangan: "",
        akun_perkiraan: "",
        nama_karyawan: "",
        nama_divisi: "",
        nama_jabatan: "",
        alamat: "",
      },
    };
  },

  created() {
    this.formData = this.$props.payrollData;
    this.ListItem = this.$props.payrollItem;
  },

  methods: {
    capitlizeFirst(str) {
      // checks for null, undefined and empty string
      if (!str) return;
      return str.match("^[a-z]")
        ? str.charAt(0).toUpperCase() + str.substring(1)
        : str;
    },
    setTerbilang(angka) {
      var bilne = [
        "",
        "satu",
        "dua",
        "tiga",
        "empat",
        "lima",
        "enam",
        "tujuh",
        "delapan",
        "sembilan",
        "sepuluh",
        "sebelas",
      ];

      if (angka < 12) {
        return bilne[angka];
      } else if (angka < 20) {
        return this.setTerbilang(angka - 10) + " belas";
      } else if (angka < 100) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 10)) +
          " puluh " +
          this.setTerbilang(parseInt(angka) % 10)
        );
      } else if (angka < 200) {
        return "seratus " + this.setTerbilang(parseInt(angka) - 100);
      } else if (angka < 1000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 100)) +
          " ratus " +
          this.setTerbilang(parseInt(angka) % 100)
        );
      } else if (angka < 2000) {
        return "seribu " + this.setTerbilang(parseInt(angka) - 1000);
      } else if (angka < 1000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000)) +
          " ribu " +
          this.setTerbilang(parseInt(angka) % 1000)
        );
      } else if (angka < 1000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000)) +
          " juta " +
          this.setTerbilang(parseInt(angka) % 1000000)
        );
      } else if (angka < 1000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000)) +
          " milyar " +
          this.setTerbilang(parseInt(angka) % 1000000000)
        );
      } else if (angka < 1000000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000000)) +
          " trilyun " +
          this.setTerbilang(parseInt(angka) % 1000000000000)
        );
      }
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatMonth(string) {
      return moment(string + "-01").format("MMMM YYYY");
    },

    formatDate(string, format) {
      return moment(string).format(format ? format : "DD MMMM YYYY");
    },
  },

  watch: {
    payrollData: function (newVal) {
      this.formData = newVal;
    },
    payrollItem: function (newVal) {
      this.ListItem = newVal;
    },
    company: function (newVal) {
      this.dataCompany = newVal;
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.title-invoice {
  font-weight: 700;
  font-size: 48px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.total-payroll {
  background: #d7ffea;
  border-radius: 0px 0px 4px 4px;
}

.total > .row:first-child {
  line-height: 40px;
}

.total {
  font-size: 14px;
  border: #e3e3e3 1px solid;
  border-radius: 5px;
}

.total .row {
  padding: 15px 25px;
}

#table-total td {
  background-color: #ffd7d7 !important;
  border: none;
  padding: 0px 25px;
  height: 50px;
  /* border-radius: 10px; */
}

#table-total tr td:first-child {
  border-top-left-radius: 10px;
}

#table-total tr td:last-child {
  border-top-right-radius: 10px;
}

.text {
  font-size: 14px;
}

.address {
  width: 400px;
  font-size: 14px;
}

.title-payroll {
  font-size: 37px;
  color: #263238;
  font-weight: 600;
}

.img-comapny img {
  width: 150px;
  height: auto;
  object-fit: cover;
  margin-bottom: 20px;
}

.text-bold {
  font-weight: 600;
}

.text-header {
  font-size: 15px;
  text-transform: uppercase;
  width: 152px;
}

.table-payroll-slip tr,
.table-payroll-slip td {
  font-size: 15px;
  border: 1px solid #e3e3e3;
  text-transform: uppercase;
}

.table-payroll-slip tr,
.table-payroll-slip td.border-colon-right {
  border-right-width: 0 !important;
}

.table-payroll-slip tr,
.table-payroll-slip td.border-colon {
  border-left: 0 !important;
}

.table-payroll-slip td {
  padding: 14px 12px !important;
  height: 25px !important;
}

.table-payroll-slip td.td-block {
  background-color: #666666;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
}

.table-payroll-slip tr.border-none,
.table-payroll-slip .border-none td {
  border: none !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f7f8;
}

.title-company {
  font-weight: 700;
  font-size: 20px;
  color: #0e2c4e;
}

.email-company {
  color: #898989;
  font-size: 16px;
}

.company-wrapper {
  width: 100%;
}

.address-company {
  color: #898989;
  font-size: 16px;
  text-align: right;
  width: 415px;
}

.item-detail {
  margin-bottom: 7px;
}

.header-detail {
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  padding: 20px 30px;
}

.table-payroll-slip td.td-block.header-table-blue {
  background-color: #68caf8;
  color: #000;
  padding: 15px 12px;
}
.table-payroll-slip td.td-block.header-table-red {
  background-color: #ff8d8d;
  color: #000;
  padding: 15px 12px;
}
</style>
