<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div class="">
            <h4 class="title">Detail Penggajian</h4>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/data-payroll">Payroll</router-link>
                </li>
                <li class="breadcrumb-item">
                  <router-link
                    :to="{
                      name: formData.id_payroll
                        ? 'DetailSalary'
                        : 'DataPayroll',
                      params: { id: formData.id_payroll },
                    }"
                  >
                    Detail Penggajian</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ formData.nama_karyawan }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <ComponentSlipRawuh
          v-if="isRawuhSlip"
          :payrollData="formData"
          :payrollItem="ListItem"
          :payrollItemDisplay="ListItemDisplay"
          :payrollDesc="descPayroll"
          :payrollPresensi="dataPresent"
          :company="dataCompany"
        ></ComponentSlipRawuh>
        <ComponentSlipFinanta
          v-else
          :payrollData="formData"
          :payrollItem="ListItem"
          :company="dataCompany"
        ></ComponentSlipFinanta>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../../components/Sidebar.vue";
import NavbarDashboard from "../../../../components/Navbar.vue";
import { ref } from "vue";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import "moment/locale/id";
import { get_PayrollSlip } from "../../../../actions/payroll";
import { showAlert } from "../../../../helper";
import ComponentSlipFinanta from "./ComponentSlipFinanta.vue";
import ComponentSlipRawuh from "./ComponentSlipRawuh.vue";
import { DATA } from "../../../../constans/Url";
// import $ from 'jquery'

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    SidebarDashboard,
    NavbarDashboard,
    ComponentSlipFinanta,
    ComponentSlipRawuh,
  },

  data() {
    return {
      isRawuhSlip: false,
      ListItem: {
        pemasukan: [],
        potongan: [],
        subsidi: [],
        nilai_pemasukan: 0,
        nilai_potongan: 0,
        nilai_subsidi: 0,
        kesehahatan: [],
      },
      ListItemDisplay: {
        pemasukan: [],
        potongan: [],
        subsidi: [],
        nilai_pemasukan: 0,
        nilai_potongan: 0,
        nilai_subsidi: 0,
        kesehahatan: [],
      },
      DataTable: "",
      descPayroll: {},
      dataPresent: {
        present: 0,
        absent: 0,
        permission: 0,
        paidleave: 0,
        overtime: 0,
      },
      dataCompany: {
        logo: "",
        email: "",
        nama: "",
        alamat: "",
      },
      selectAll: false,
      formData: {
        id: "",
        id_karyawan: "",
        id_payroll: "-",
        kategori: "Gaji",
        bulan: "",
        tanggal_bayar: "",
        tgl_masuk: "",
        keterangan: "",
        akun_perkiraan: "",
        nama_karyawan: "",
        nama_divisi: "",
        nama_jabatan: "",
        alamat: "",
        nama_level: "",
        logo: "",
        nama_bank: "",
        nomor_rekening: "",
        performance: "",
      },
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  created() {
    this.getData();
  },
  methods: {
    capitlizeFirst(str) {
      // checks for null, undefined and empty string
      if (!str) return;
      return str.match("^[a-z]")
        ? str.charAt(0).toUpperCase() + str.substring(1)
        : str;
    },
    setTerbilang(angka) {
      var bilne = [
        "",
        "satu",
        "dua",
        "tiga",
        "empat",
        "lima",
        "enam",
        "tujuh",
        "delapan",
        "sembilan",
        "sepuluh",
        "sebelas",
      ];

      if (angka < 12) {
        return bilne[angka];
      } else if (angka < 20) {
        return this.setTerbilang(angka - 10) + " belas";
      } else if (angka < 100) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 10)) +
          " puluh " +
          this.setTerbilang(parseInt(angka) % 10)
        );
      } else if (angka < 200) {
        return "seratus " + this.setTerbilang(parseInt(angka) - 100);
      } else if (angka < 1000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 100)) +
          " ratus " +
          this.setTerbilang(parseInt(angka) % 100)
        );
      } else if (angka < 2000) {
        return "seribu " + this.setTerbilang(parseInt(angka) - 1000);
      } else if (angka < 1000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000)) +
          " ribu " +
          this.setTerbilang(parseInt(angka) % 1000)
        );
      } else if (angka < 1000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000)) +
          " juta " +
          this.setTerbilang(parseInt(angka) % 1000000)
        );
      } else if (angka < 1000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000)) +
          " milyar " +
          this.setTerbilang(parseInt(angka) % 1000000000)
        );
      } else if (angka < 1000000000000000) {
        return (
          this.setTerbilang(Math.floor(parseInt(angka) / 1000000000000)) +
          " trilyun " +
          this.setTerbilang(parseInt(angka) % 1000000000000)
        );
      }
    },

    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatMonth(string) {
      return moment(string + "-01").format("MMMM YYYY");
    },

    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },
    getDescPayroll(key) {
      if (
        this.descPayroll &&
        this.descPayroll[key] &&
        this.descPayroll[key] != undefined
      ) {
        return this.descPayroll[key];
      } else {
        return "";
      }
    },
    getData() {
      get_PayrollSlip(
        this.$route.params.id,
        (res) => {
          var { detail, data, company } = res;
          this.formData.id = data.id;
          this.formData.id_payroll = data.id_payroll;
          this.formData.kategori = data.kategori;
          this.formData.bulan = data.bulan;
          this.formData.tanggal_bayar = data.tanggal_bayar;
          this.formData.tgl_masuk = data.tgl_masuk;
          this.formData.id_karyawan = data.id_karyawan;
          this.formData.nama_karyawan = data.nama_karyawan;
          this.formData.nama_divisi = data.nama_divisi;
          this.formData.nama_jabatan = data.nama_jabatan;
          this.formData.alamat = data.alamat_company;
          this.formData.nama_bank = data.nama_bank;
          this.formData.nomor_rekening = data.nomor_rekening;
          this.formData.performance = data.performance;

          if (data.raw_presensi) {
            this.dataPresent = data.raw_presensi;
            this.descPayroll = data.raw_presensi.data_payroll;
          }

          var dataKesehatan = detail.subsidi.filter(
            (e) =>
              e.tipe == 5 ||
              e.tipe == 7 ||
              (e.tipe == 10 &&
                e.id != this.getDescPayroll("tunjangan_pensiun_item"))
          );

          this.ListItem = detail;
          this.ListItem.kesehahatan = dataKesehatan;
          this.ListItemDisplay = detail;
          this.ListItemDisplay.kesehahatan = dataKesehatan;

          if (
            data.raw_presensi &&
            data.raw_presensi.data_payroll &&
            !(Object.keys(data).length === 0 && data.constructor === Object)
          ) {
            this.isRawuhSlip = true;
          } else {
            this.isRawuhSlip = false;
          }
          this.ListItem = detail;

          this.dataCompany = {
            logo: company.logo ? DATA.COMPANY(company.logo) : "",
            nama: company.nama,
            email: company.email,
            alamat: company.alamat,
          };
        },
        (err) => {
          console.log(err);
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Data Slip gaji tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
            onDismiss: () => {
              this.$router.push({
                name: "DataPayroll",
              });
            },
          });
        }
      );
    },
  },
};
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>

<style scoped>
.title-invoice {
  font-weight: 700;
  font-size: 48px;
  background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

.total-payroll {
  background: #d7ffea;
  border-radius: 0px 0px 4px 4px;
}

.total > .row:first-child {
  line-height: 40px;
}

.total {
  font-size: 14px;
  border: #e3e3e3 1px solid;
  border-radius: 5px;
}

.total .row {
  padding: 15px 25px;
}

#table-total td {
  background-color: #ffd7d7 !important;
  border: none;
  padding: 0px 25px;
  height: 50px;
  /* border-radius: 10px; */
}

#table-total tr td:first-child {
  border-top-left-radius: 10px;
}

#table-total tr td:last-child {
  border-top-right-radius: 10px;
}

.text {
  font-size: 14px;
}

.address {
  width: 400px;
  font-size: 14px;
}

.title-payroll {
  font-size: 37px;
  color: #263238;
  font-weight: 600;
}
</style>
