<template>
  <div class="card info-detail">
    <div class="d-flex justify-content-between align-items-center">
      <div class="company-wrapper d-flex align-items-center">
        <img :src="dataCompany.logo" v-if="dataCompany.logo" class="mr-3" style="width: 90px; height: auto" />
        <div class="">
          <div class="title-company">{{ dataCompany.nama }}</div>
          <div class="email-company">{{ dataCompany.email }}</div>
        </div>
      </div>

      <div class="address-company">
        {{ dataCompany.alamat }}
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-between mt-5 header-detail">
      <div class="">
        <div class="title-payroll mb-3">Payslip</div>
        <div class="d-flex align-items-center item-detail">
          <div class="text-header">Periode bulan</div>
          <div class="text-bold">: {{ formatDate(`${formData.bulan}-01`, "MMM YYYY") }}</div>
        </div>
        <div class="d-flex align-items-center item-detail">
          <div class="text-header">tanggal bayar</div>
          <div class="text-bold">: {{ formatDate(`${formData.tanggal_bayar}`, "DD MMM YYYY") }}</div>
        </div>
      </div>
      <div class="">

        <div class="d-flex align-items-center item-detail">
          <div class="text-header">NAMA</div>
          <div class="text-bold">: {{ formData.nama_karyawan }}</div>
        </div>
        <div class="d-flex align-items-center item-detail">
          <div class="text-header">NIK</div>
          <div class="text-bold">: {{ formData.id_karyawan }}</div>
        </div>
        <div class="d-flex align-items-center item-detail">
          <div class="text-header">DEPARTEMEN/LEVEL</div>
          <div class="text-bold">: {{ formData.nama_divisi }} / {{ formData.nama_jabatan }}</div>
        </div>
        <div class="d-flex align-items-center item-detail">
          <div class="text-header">ACTIVE SINCE</div>
          <div class="text-bold">: {{ formatDate(formData.tgl_masuk, "DD MMM YYYY") }}</div>
        </div>
      </div>
      <div class="">

        <div class="d-flex align-items-center item-detail">
          <div class="text-header">WORKING YEARS</div>
          <div class="text-bold">: {{ parseFloat(parseFloat(getDescPayroll("masa_kerja")).toFixed(2)) }} YEARS</div>
        </div>
        <div class="d-flex align-items-center item-detail">
          <div class="text-header">MARRIED STATUS</div>
          <div class="text-bold">: <span v-if="getDescPayroll('jumlah_pasangan') > 0">Menikah</span>
            <span v-else>Belum Menikah</span></div>
        </div>
        <div class="d-flex align-items-center item-detail">
          <div class="text-header">JUMLAH ANAK</div>
          <div class="text-bold">: {{ getDescPayroll("jumlah_anak_actual") }}</div>
        </div>
        <div class="d-flex align-items-center item-detail">
          <div class="text-header">BANK ACCOUNT</div>
          <div class="text-bold">: {{ formData.nomor_rekening }} ({{ formData.nama_bank }})</div>
        </div>
      </div>
    </div>
    <table class="table table-payroll-slip mt-5 table-striped">
      <tbody>
        <tr>
          <td colspan="10" class="td-block header-table-blue">Gaji Pokok</td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("salary_flat_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right"></td>
          <td></td>
          <td class="border-colon-right"></td>
          <td class="border-colon"></td>
          <td class="text-right"></td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("salary_flat_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("salary_flat_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("salary_absen_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ parseFloat(getDescPayroll("working_hours")) }}
          </td>
          <td>Jam</td>
          <td class="border-colon-right">Upah / Hari</td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("salary_perhours")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("salary_absen_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("salary_absen_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("gaji_absen_dinas_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">{{ dataPresent.present_business_trip }}</td>
          <td>Hari</td>
          <td class="border-colon-right">Upah / Hari</td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("salary_perday_luar")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("gaji_absen_dinas_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("gaji_absen_dinas_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("lembur_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">{{ dataPresent.overtime }}</td>
          <td>Jam</td>
          <td class="border-colon-right">Upah / Jam Lembur</td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("lembur_perhari")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("lembur_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{ formatMoney(getNameItem(getDescPayroll("lembur_item"), "nilai")) }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">Paid Leave</td>
          <td class="border-colon">:</td>
          <td class="text-right">{{ dataPresent.paidleave }}</td>
          <td>Hari</td>
          <td class="border-colon-right"></td>
          <td class="border-colon">:</td>
          <td class="text-right"></td>
          <td class="border-colon-right"></td>
          <td class="border-colon"></td>
          <td class="text-right text-bold"></td>
        </tr>
        <tr>
          <td class="border-colon-right">Permissions</td>
          <td class="border-colon">:</td>
          <td class="text-right">{{ dataPresent.permission.day }}</td>
          <td>Hari</td>
          <td class="border-colon-right"></td>
          <td class="border-colon">:</td>
          <td class="text-right"></td>
          <td class="border-colon-right"></td>
          <td class="border-colon"></td>
          <td class="text-right text-bold"></td>
        </tr>
        <tr>
          <td colspan="10" class="td-block header-table-blue">Tunjangan Dasar Tetap</td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_keluarga_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">{{ getDescPayroll("jumlah_anak") }}</td>
          <td>{{ getDescPayroll("jumlah_anak") == 1 ? "Child" : "Childs" }}</td>
          <td class="border-colon-right">
            Tarif {{ getNameItem(getDescPayroll("tunjangan_keluarga_item")) }}
          </td>
          <td class="border-colon">:</td>
          <!-- <td class="text-right">{{ formatMoney(getDescPayroll('amount_pasangan') + getDescPayroll('amount_anak')) }}</td> -->
          <td class="text-right">
            {{ formatMoney(getDescPayroll("amount_pasangan")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_keluarga_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_keluarga_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("position_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right"></td>
          <td></td>
          <td class="border-colon-right">
            Tarif {{ getNameItem(getDescPayroll("position_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("position_allowance")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("position_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("position_allowance_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("communication_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right"></td>
          <td></td>
          <td class="border-colon-right">
            Tarif
            {{ getNameItem(getDescPayroll("communication_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("communication_allowance")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("communication_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(
                  getDescPayroll("communication_allowance_item"),
                  "nilai"
                )
              )
            }}
          </td>
        </tr>
        <tr>
          <td colspan="10" class="td-block header-table-blue">
            Tunjangan Akomodasi Berdasarkan absensi
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_makan_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_makan_item"), "nilai") /
                  getDescPayroll("tunjangan_makan_perhari")
              )
            }}
          </td>
          <td class="text-center">x</td>
          <td class="border-colon-right">
            Tarif {{ getNameItem(getDescPayroll("tunjangan_makan_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("tunjangan_makan_perhari")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_makan_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_makan_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_transport_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_transport_item"), "nilai") /
                  getDescPayroll("tunjangan_transport_perhari")
              )
            }}
          </td>
          <td class="text-center">x</td>
          <td class="border-colon-right">
            Tarif {{ getNameItem(getDescPayroll("tunjangan_transport_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{
              formatMoney(getDescPayroll("tunjangan_transport_perhari"), "nilai")
            }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_transport_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_transport_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_makan_luar_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">{{ dataPresent.present_business_trip }}</td>
          <td class="text-center">x</td>
          <td class="border-colon-right">
            Tarif {{ getNameItem(getDescPayroll("tunjangan_makan_luar_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{
              formatMoney(getDescPayroll("tunjangan_makan_luar_perhari"), "nilai")
            }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_makan_luar_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_makan_luar_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td colspan="10" class="td-block header-table-blue">
            Tunjangan kesehatan & ketenagakerjaan
          </td>
        </tr>
        <tr v-for="(value, index) in ListItemDisplay.kesehahatan" :key="index">
          <td class="border-colon-right">{{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td></td>
          <td></td>
          <td class="border-colon-right">Tarif {{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td class="text-right">{{ formatMoney(value.nilai) }}</td>
          <td class="border-colon-right">{{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">{{ formatMoney(value.nilai) }}</td>
        </tr>
        <tr>
          <td colspan="10" class="td-block header-table-blue">Tunjangan Lainnya</td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("masa_kerja_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ parseFloat(parseFloat(getDescPayroll("masa_kerja")).toFixed(2)) }}
          </td>
          <td></td>
          <td class="border-colon-right">
            Tarif {{ getNameItem(getDescPayroll("masa_kerja_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("masa_kerja_nilai")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("masa_kerja_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(getNameItem(getDescPayroll("masa_kerja_item"), "nilai"))
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">Penilaian Direksi & manager(%)</td>
          <td class="border-colon">:</td>
          <td class="text-right">{{ parseFloat(formData.performance) }}%</td>
          <td></td>
          <td class="border-colon-right">
            % darii gaji flat berdasarkan level jabatan
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("kinerja_dasar")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_kinerja_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_kinerja_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr>
          <td class="border-colon-right">
            Tunjangan Pensiun Dasar (5% x tunjangan masa kerja)
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("tunjangan_pensiun_dasar")) }}
          </td>
          <td></td>
          <td class="border-colon-right">
            Elemen tunjangan pensiun (10% x tunjangan kinerja)
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("tunjangan_pensiun_element")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_pensiun_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_pensiun_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr v-if="getDescPayroll('tunjangan_non_item') != ''">
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_non_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ getDescPayroll("tunjangan_non_item") != "" ? "1" : "0" }}
          </td>
          <td></td>
          <td class="border-colon-right">
            Tarif {{ getNameItem(getDescPayroll("tunjangan_non_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("tunjangan_non_struktural")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("tunjangan_non_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("tunjangan_non_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr v-if="getDescPayroll('education_allowance_item') != ''">
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("education_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right"></td>
          <td></td>
          <td class="border-colon-right">
            Tarif {{ getNameItem(getDescPayroll("education_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right">
            {{ formatMoney(getDescPayroll("education_allowance")) }}
          </td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("education_allowance_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                getNameItem(getDescPayroll("education_allowance_item"), "nilai")
              )
            }}
          </td>
        </tr>
        <tr v-for="(value, index) in otherAllowance()" :key="index">
          <td class="border-colon-right">{{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="border-colon-right">{{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">{{ formatMoney(value.nilai) }}</td>
        </tr>
        <tr>
          <td colspan="10" class="td-block header-table-blue">Intensif Tidak tetap</td>
        </tr>
        <tr>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("man_power_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right"></td>
          <td></td>
          <td class="border-colon-right"></td>
          <td class="text-right border-colon"></td>
          <td></td>
          <td class="border-colon-right">
            {{ getNameItem(getDescPayroll("man_power_item")) }}
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{
              formatMoney(getNameItem(getDescPayroll("man_power_item"), "nilai"))
            }}
          </td>
        </tr>
        <tr>
          <td colspan="9" class="td-block text-center header-table-blue">Total Gaji & Tunjangan</td>
          <td class="text-right text-bold">
            {{ formatMoney(ListItem.nilai_pemasukan + ListItem.nilai_subsidi) }}
          </td>
        </tr>
        <tr>
          <td colspan="10"></td>
        </tr>
        <tr>
          <td colspan="10" class="td-block header-table-red ">Potongan</td>
        </tr>
        <tr v-for="(value, index) in getPotongan()" :key="index">
          <td class="border-colon-right">{{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td></td>
          <td></td>
          <td class="border-colon-right"></td>
          <td class="border-colon"></td>
          <td></td>
          <td class="border-colon-right">{{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">{{ formatMoney(value.nilai) }}</td>
        </tr>
        <tr>
          <td class="border-colon-right">
            Potongan BPJS Kesehatan & Ketenagakerjaan
          </td>
          <td class="border-colon">:</td>
          <td></td>
          <td></td>
          <td class="border-colon-right"></td>
          <td class="border-colon"></td>
          <td></td>
          <td class="border-colon-right" style="font-size: 10px">
            Nominal BPJS Kesehatan & Bpjs Ketenagakerjaan akan dipotong karena
            dibayarkan kolektif
          </td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">
            {{ formatMoney(getNominalBPJS()) }}
          </td>
        </tr>
        <tr v-for="(value, index) in displayOtherSubsidy()" :key="index">
          <td class="border-colon-right">{{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td></td>
          <td></td>
          <td class="border-colon-right"></td>
          <td class="border-colon"></td>
          <td></td>
          <td class="border-colon-right">{{ value.nama }}</td>
          <td class="border-colon">:</td>
          <td class="text-right text-bold">{{ formatMoney(value.nilai) }}</td>
        </tr>
        <tr>
          <td colspan="9" class="td-block text-center header-table-blue ">
            Total Gaji & Tunjangan yang diterima
          </td>
          <td class="text-right text-bold">
            {{
              formatMoney(
                ListItem.nilai_pemasukan +
                  ListItem.nilai_subsidi -
                  (ListItem.nilai_potongan + ListItem.nilai_subsidi)
              )
            }}
          </td>
        </tr>
      </tbody>
      <!-- <tr class="border-none">
        <td style="width: 22%"></td>
        <td style="width: 0.5%"></td>
        <td style="width: 5%"></td>
        <td style="width: 5%"></td>
        <td style="width: 21%"></td>
        <td style="width: 0.5%"></td>
        <td style="width: 12%"></td>
        <td style="width: 22%"></td>
        <td style="width: 0.5%"></td>
        <td style="width: 16%"></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">Periode bulan</td>
        <td class="border-colon">:</td>
        <td colspan="3" class="text-bold">
          {{ formatDate(`${formData.bulan}-01`, "MMM YYYY") }}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">tgl bayar</td>
        <td class="border-colon">:</td>
        <td colspan="3" class="text-bold">
          {{ formatDate(`${formData.tanggal_bayar}`, "DD MMM YYYY") }}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">Nama</td>
        <td class="border-colon">:</td>
        <td colspan="3" class="text-bold">{{ formData.nama_karyawan }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">NIK</td>
        <td class="border-colon">:</td>
        <td colspan="3" class="text-bold">{{ formData.id_karyawan }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">DEPARTEMEN/LEVEL</td>
        <td class="border-colon">:</td>
        <td colspan="3" class="text-bold">
          {{ formData.nama_divisi }} / {{ formData.nama_jabatan }}
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">ACTIVE SINCE</td>
        <td class="border-colon">:</td>
        <td class="text-bold" colspan="2">
          {{ formatDate(formData.tgl_masuk, "DD MMM YYYY") }}
        </td>
        <td></td>
        <td>{{ formatMoney(dateDiff(formData.tgl_masuk, formData.tanggal_bayar)) }} Days</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr> -->
      <!-- <tr class="border-none">
        <td class="border-colon-right">working years</td>
        <td class="border-colon">:</td>
        <td class="text-bold">
          {{ parseFloat(parseFloat(getDescPayroll("masa_kerja")).toFixed(2)) }}
        </td>
        <td></td>
        <td>Years</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">Married Status</td>
        <td class="border-colon">:</td>
        <td class="text-bold">
          <span v-if="getDescPayroll('jumlah_pasangan') > 0">Menikah</span>
          <span v-else>Belum Menikah</span>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">Jumlah Anak</td>
        <td class="border-colon">:</td>
        <td class="text-bold">{{ getDescPayroll("jumlah_anak_actual") }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr class="border-none">
        <td class="border-colon-right">Bank Account</td>
        <td class="border-colon">:</td>
        <td class="text-bold" colspan="3">
          {{ formData.nomor_rekening }} ({{ formData.nama_bank }})
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr> -->


    </table>
  </div>
</template>

<script>
  import moment from "moment";
  import "moment/locale/id";

  // import { showAlert } from "../../../../helper";
  // import { dataUrl } from "../../../../commons/url";
  // var converter = require("number-to-words");

  export default {
    name: "ComponentSipRawuh",

    props: {
      payrollData: Object,
      payrollItem: Object,
      payrollItemDisplay: Object,
      payrollPresensi: Object,
      payrollDesc: Object,
      company: Object,
    },

    data() {
      return {
        dataCompany: {
          logo: "",
          email: "",
          nama: "",
          alamat: "",
        },
        ListItem: {
          pemasukan: [],
          potongan: [],
          subsidi: [],
          nilai_pemasukan: 0,
          nilai_potongan: 0,
          nilai_subsidi: 0,
          kesehahatan: [],
        },
        ListItemDisplay: {
          pemasukan: [],
          potongan: [],
          subsidi: [],
          kesehahatan: [],
          nilai_pemasukan: 0,
          nilai_potongan: 0,
          nilai_subsidi: 0,
        },
        dataPresent: {
          present: 0,
          absent: 0,
          permission: 0,
          paidleave: 0,
          overtime: 0,
        },
        descPayroll: {},
        formData: {
          id: "",
          id_karyawan: "",
          id_payroll: "-",
          kategori: "Gaji",
          bulan: "",
          tanggal_bayar: "",
          tgl_masuk: "",
          keterangan: "",
          akun_perkiraan: "",
          nama_karyawan: "",
          nama_divisi: "",
          nama_jabatan: "",
          alamat: "",
          nama_level: "",
          logo: "",
          nama_bank: "",
          nomor_rekening: "",
          performance: "",
        },
      };
    },

    mounted() {
      this.formData = this.$props.payrollData;
      this.ListItem = this.$props.payrollItem;
      this.ListItemDisplay = this.$props.payrollItemDisplay;
      this.dataPresent = this.$props.payrollPresensi;
      this.descPayroll = this.$props.payrollDesc;
    },

    methods: {
      capitlizeFirst(str) {
        // checks for null, undefined and empty string
        if (!str) return;
        return str.match("^[a-z]") ?
          str.charAt(0).toUpperCase() + str.substring(1) :
          str;
      },
      formatMonth(string) {
        return moment(string + "-01").format("MMMM YYYY");
      },

      convertPermission(day, hour) {
        var string = "";
        if (day > 0) {
          string += ` ${day} ${day > 1 ? "days" : "day"}`;
        }
        if (hour > 0) {
          string += ` ${hour} ${hour > 1 ? "hours" : "hour"}`;
        }
        if (string == "") {
          string = "0 hour";
        }

        return string;
      },
      formatMoney(a) {
        if (a) {
          return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
          return "0";
        }
      },
      formatDate(date, format = "YYYY-MM-DD") {
        if (date) {
          return moment(date).format(format);
        }
        return "";
      },
      otherAllowance() {
        var item = [];
        var itemDisplay = [
          this.getDescPayroll("salary_flat_item"),
          this.getDescPayroll("position_allowance_item"),
          this.getDescPayroll("communication_allowance_item"),
          this.getDescPayroll("education_allowance_item"),
          this.getDescPayroll("salary_absen_item"),
          this.getDescPayroll("tunjangan_makan_item"),
          this.getDescPayroll("tunjangan_transport_item"),
          this.getDescPayroll("tunjangan_makan_luar_item"),
          this.getDescPayroll("masa_kerja_item"),
          this.getDescPayroll("tunjangan_non_item"),
          this.getDescPayroll("tunjangan_kinerja_item"),
          this.getDescPayroll("tunjangan_pensiun_item"),
          this.getDescPayroll("tunjangan_keluarga_item"),
          this.getDescPayroll("gaji_absen_dinas_item"),
          this.getDescPayroll("lembur_item"),
          this.getDescPayroll("man_power_item"),
        ];
        var pemasukan = this.ListItemDisplay.pemasukan.filter(
          (e) => itemDisplay.indexOf(e.id) < 0
        );
        var subsidi = this.ListItemDisplay.subsidi.filter(
          (e) => itemDisplay.indexOf(e.id) < 0
        );
        var subsidifix = subsidi.filter((e) => {
          var tunjangan = this.ListItemDisplay.kesehahatan.findIndex(
            (el) => e.id == el.id
          );
          if (tunjangan >= 0) {
            return 0;
          } else {
            return 1;
          }
        });
        item = [...pemasukan, ...subsidifix];
        return item;
      },
      displayOtherSubsidy() {
        var item = this.ListItemDisplay.subsidi.filter((e) => {
          var tunjangan = this.ListItemDisplay.kesehahatan.findIndex(
            (el) => e.id == el.id
          );
          // console.log(tunjangan)
          if (tunjangan >= 0) {
            return 0;
          } else {
            return 1;
          }
        });
        return item;
      },
      getPotongan() {
        var item = this.ListItemDisplay.potongan.filter(
          (e) => e.tipe != 13 && e.tipe != "13" && e.tipe != "14" && e.tipe != 14
        );
        return item;
      },
      getNominalBPJS() {
        var nominal = 0;
        var item = this.ListItemDisplay.potongan.filter(
          (e) => e.tipe == 13 || e.tipe == "13" || e.tipe == "14" || e.tipe == 14
        );

        for (const key in item) {
          if (Object.hasOwnProperty.call(item, key)) {
            const element = item[key];
            // console.log(element)
            nominal += element.nilai;
          }
        }

        for (const key in this.ListItemDisplay.kesehahatan) {
          if (Object.hasOwnProperty.call(this.ListItemDisplay.kesehahatan, key)) {
            const element = this.ListItemDisplay.kesehahatan[key];
            nominal += element.nilai;
          }
        }
        return nominal;
      },
      dateDiff(start, end) {
        var dateStart = moment(start);
        var dateEnd = moment(end);
        return dateEnd.diff(dateStart, "days");
      },
      getDescPayroll(key) {
        if (
          this.descPayroll &&
          this.descPayroll[key] &&
          this.descPayroll[key] != undefined
        ) {
          return this.descPayroll[key];
        } else {
          return "";
        }
      },
      getNameItem(id_item, typeReturn = "nama") {
        var find = this.ListItemDisplay.pemasukan.find((e) => e.id == id_item);
        if (!find || !find.id) {
          find = this.ListItemDisplay.subsidi.find((e) => e.id == id_item);
          if (!find || !find.id) {
            find = this.ListItemDisplay.potongan.find((e) => e.id == id_item);
            if (!find || !find.id) {
              return "";
            }
          }
        }

        if (typeReturn == "nama") {
          return find.nama;
        } else if (typeReturn == "nilai") {
          return find.nilai;
        }
      },
    },

    watch: {
      payrollData: function (newVal) {
        this.formData = newVal;
      },
      payrollItem: function (newVal) {
        this.ListItem = newVal;
      },
      payrollPresensi: function (newVal) {
        this.dataPresent = newVal;
      },
      payrollItemDisplay: function (newVal) {
        this.ListItemDisplay = newVal;
      },
      payrollDesc: function (newVal) {
        this.descPayroll = newVal;
      },
      company: function (newVal) {
        this.dataCompany = newVal;
      },
    },
  };
</script>

<style scoped src="../../../../assets/css/style.css"></style>

<style scoped src="../../../../assets/css/custom.css"></style>
<style scoped>
  .title-invoice {
    font-weight: 700;
    font-size: 48px;
    background: linear-gradient(102.18deg, #6ec0fe -17.12%, #61efe7 95.5%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }

  .card {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
  }

  .total-payroll {
    background: #def0d8;
    border-radius: 0px 0px 4px 4px;
  }

  .total>.row:first-child {
    line-height: 40px;
  }

  .total {
    font-size: 14px;
    border: #e3e3e3 1px solid;
    border-radius: 5px;
  }

  .total .row {
    padding: 15px 25px;
  }

  #table-total td {
    background-color: #ffd7d7 !important;
    border: none;
    padding: 0px 25px;
    height: 50px;
    /* border-radius: 10px; */
  }

  #table-total tr td:first-child {
    border-top-left-radius: 10px;
  }

  #table-total tr td:last-child {
    border-top-right-radius: 10px;
  }

  .text {
    font-size: 14px;
  }

  .address {
    width: 400px;
    font-size: 14px;
  }

  .title-payroll {
    font-size: 32px;
    color: #263238;
    font-weight: 600;
  }

  .img-comapny img {
    width: 150px;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .text-bold {
    font-weight: 600;
  }

  .text-header {
    font-size: 15px;
    text-transform: uppercase;
    width: 152px;
  }

  .table-payroll-slip tr,
  .table-payroll-slip td {
    font-size: 15px;
    border: 1px solid #E3E3E3;
    text-transform: uppercase;
  }

  .table-payroll-slip tr,
  .table-payroll-slip td.border-colon-right {
    border-right-width: 0 !important;
  }

  .table-payroll-slip tr,
  .table-payroll-slip td.border-colon {
    border-left: 0 !important;
  }

  .table-payroll-slip td {
    padding: 14px 12px !important;
    height: 25px !important;
  }

  .table-payroll-slip td.td-block {
    background-color: #666666;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
  }

  .table-payroll-slip tr.border-none,
  .table-payroll-slip .border-none td {
    border: none !important;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: #F5F7F8;
  }

  .title-company {
    font-weight: 700;
    font-size: 20px;
    color: #0E2C4E;
  }

  .email-company {
    color: #898989;
    font-size: 16px;
  }

  .company-wrapper {
    width: 100%;
  }

  .address-company {
    color: #898989;
    font-size: 16px;
    text-align: right;
    width: 415px;
  }

  .item-detail {
    margin-bottom: 7px;
  }

  .header-detail {
    border-radius: 5px;
    border: 1px solid #E3E3E3;
    padding: 20px 30px;
  }

  .table-payroll-slip td.td-block.header-table-blue {
    background-color: #68CAF8;
    color: #000;
    padding: 15px 12px;
  }
  .table-payroll-slip td.td-block.header-table-red {
    background-color: #FF8D8D;
    color: #000;
    padding: 15px 12px;
  }
</style>